<!--
 * @Description: 新增属性
 * @Author: 琢磨先生
 * @Date: 2022-05-14 12:40:34
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-09-29 14:22:56
-->


<template>
  <el-drawer
    :title="title"
    v-model="drawer"
    direction="rtl"
    size="80%"
    custom-class="drawer"
  >
    <el-form>
      <el-form-item label="">
        <el-button
          type="success"
          size="small"
          @click="loadData"
          round
          icon="RefreshRight"
          >刷新</el-button
        >
        <el-button type="primary" size="small" @click="onAdd" round icon="plus"
          >新增</el-button
        >
      </el-form-item>
    </el-form>
    <el-tabs v-model="activeName">
      <el-tab-pane
        v-for="item in types"
        :key="item.value"
        :label="item.text"
        :name="item.value"
      >
        <el-table :data="tableData.filter((o) => o.type == item.value)">
          <el-table-column prop="id" label="编号" width="80"> </el-table-column>
          <el-table-column
            prop="category.full_name"
            label="来自类目"
            width="200"
          >
          </el-table-column>
          <el-table-column prop="name" label="名称" width="180">
          </el-table-column>
          <el-table-column prop="input_type_text" label="输入类型" width="80">
          </el-table-column>
          <el-table-column label="属性值" width="120">
            <template #default="scope">
              <div class="flex-row flex-between">
                <el-dropdown
                  v-if="scope.row.values && scope.row.values.length > 0"
                >
                  <span class="el-dropdown-link">
                    {{ scope.row.values.length }}组
                    <el-icon class="el-icon--right">
                      <arrow-down />
                    </el-icon>
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item
                        v-for="item in scope.row.values"
                        :key="item.id"
                        >{{ item.value }}</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
                <div v-if="scope.row.category_id == category_id">
                  <el-button
                    type="text"
                    size="small"
                    v-if="
                      ['0', '1', '3'].findIndex(
                        (o) => o == scope.row.input_type
                      ) > -1
                    "
                    @click="onSetValue(scope.row)"
                    >设置</el-button
                  >
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="is_required" label="必填" width="80">
            <template #default="scope">
              {{ scope.row.is_required ? "是" : "否" }}
            </template>
          </el-table-column>
          <el-table-column prop="is_search" label="可搜索" width="80">
            <template #default="scope">
              {{ scope.row.is_search ? "是" : "否" }}
            </template>
          </el-table-column>
          <el-table-column prop="is_stop" label="停用" width="80">
            <template #default="scope">
              <el-switch
                :disabled="scope.row.category_id != category_id"
                v-model="scope.row.is_stop"
                @change="stopSwitch(scope.row)"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="is_multiple" label="自定义">
            <template #default="scope">
              <div>多选：{{ scope.row.is_multiple ? "是" : "否" }}</div>
              <div>传图：{{ scope.row.is_open_image ? "是" : "否" }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="create_at" label="创建时间" width="180">
          </el-table-column>
          <el-table-column fixed="right" width="140" label="操作">
            <template #default="scope">
              <el-button
                type="text"
                size="small"
                v-if="scope.row.category_id == category_id"
                @click="onEdit(scope.row)"
                >修改</el-button
              >
              <el-popconfirm
                v-if="scope.row.category_id == category_id"
                title="您确定要删除？"
                @confirm="onDelProperty(scope.row)"
              >
                <template #reference>
                  <el-button size="small" type="text" class="btn-del"
                    >删除</el-button
                  >
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </el-drawer>
  <!-- 新增、修改 -->
  <el-dialog
    v-model="dialogVisible"
    title="新增属性"
    width="800px"
    draggable
    :close-on-click-modal="false"
    :destroy-on-close="true"
  >
    <el-form
      :model="form"
      ref="formProperty"
      :rules="rules"
      label-width="140px"
    >
      <el-form-item label="名称" prop="name">
        <el-col :span="10">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="属性组">
        <el-select
          v-model="form.group_id"
          clearable
          filterable
          remote
          placeholder="请输入属性组关键字查询"
          :remote-method="remoteGroup"
          :loading="remoteLoading"
        >
          <el-option
            v-for="item in groupOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="类型" prop="type" v-if="!form.id">
        <el-radio-group v-model="form.type">
          <el-radio
            :label="item.value"
            v-for="item in enums.property_types"
            :key="item.value"
            >{{ item.text }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="输入类型" prop="input_type">
        <el-radio-group v-model="form.input_type">
          <el-radio
            :label="item.value"
            v-for="item in enums.property_input_types"
            :key="item.value"
            :disabled="form.type == 1 && item.value != 1 && item.value != 5"
            >{{ item.text }}</el-radio
          >
        </el-radio-group>
      </el-form-item>

      <el-form-item label="属性提示描述" prop="tip">
        <el-col :span="20">
          <el-input
            v-model="form.tip"
            type="textarea"
            placeholder="描述内容"
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="单位" prop="unit">
        <el-col :span="5">
          <el-input v-model="form.unit" placeholder=""></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="序号(倒序)" prop="sequence">
        <el-col :span="5">
          <el-input
            v-model="form.sequence"
            placeholder="值越大越靠前"
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="是否必填">
        <el-switch v-model="form.is_required"></el-switch>
        <div class="help-block">
          <el-icon>
            <WarningFilled />
          </el-icon>
          商品维护时内容是否必填
        </div>
      </el-form-item>
      <el-form-item label="是否搜索">
        <el-switch v-model="form.is_search"></el-switch>
        <div class="help-block">
          <el-icon>
            <WarningFilled />
          </el-icon>
          当前属性是否参与商品的搜索
        </div>
      </el-form-item>
      <el-form-item label="停用">
        <el-switch v-model="form.is_stop"></el-switch>
      </el-form-item>

      <el-form-item label="允许自定义SKU" v-if="form.type == 1">
        <el-switch v-model="form.is_allow_custom"></el-switch>
        <div class="help-block">
          <el-icon> <WarningFilled /> </el-icon>允许SKU添加自定义值 ,
          用于销售属性
        </div>
      </el-form-item>
      <el-form-item label="允许SKU备注" v-if="form.type == 1">
        <el-switch v-model="form.is_allow_remark"></el-switch>
        <div class="help-block">
          <el-icon> <WarningFilled /> </el-icon>允许SKU添加备注信息 ,
          用于销售属性
        </div>
      </el-form-item>
      <!-- <el-form-item label="自定义传图片" v-if="form.input_type == 5">
        <el-switch v-model="form.is_open_image"></el-switch>
        <div class="help-block">
          <el-icon> <WarningFilled /> </el-icon>输入类型自定义时可上传图片 ,
          用于销售属性
        </div>
      </el-form-item> -->
      <el-form-item
        label="属性值"
        v-if="
          !form.id &&
          (form.input_type == 0 || form.input_type == 1 || form.input_type == 3)
        "
      >
        <el-table ref="table" :data="valueTables" border>
          <el-table-column prop="value" label="属性值"> </el-table-column>
          <el-table-column label="操作">
            <template #default="scope">
              <el-button
                type="text"
                size="small"
                class="text-danger"
                @click="removeValue(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 20px; display: flex">
          <el-input
            v-model="value"
            placeholder="请输入属性值"
            size="small"
            clearable
          ></el-input>
          <el-button
            type="primary"
            size="small"
            style="margin-left: 10px"
            @click="addValue"
            >添加属性值</el-button
          >
        </div>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="confirmClick" :loading="saving"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
  <set-property-value
    :item="current"
    @change="loadData"
    @close="current = null"
  ></set-property-value>
</template>

<script>
import SetPropertyValue from "@/views/mall/category/set_property_value";
export default {
  components: {
    SetPropertyValue,
  },
  data() {
    return {
      saving: false,
      drawer: false,
      loading: false,
      //
      posting: false,
      //显示dialog
      dialogVisible: false,
      title: "",
      types: [],
      tableData: [],
      activeName: "",
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: "类型必选",
            trigger: "blur",
          },
        ],
        input_type: [
          {
            required: true,
            message: "输入类型必选",
            trigger: "blur",
          },
        ],
      },
      //
      enums: {},
      //新增属性时添加的属性值记录
      valueTables: [],
      //添加属性值时的文本框输入
      value: "",
      //属性组搜索指示
      remoteLoading: false,
      //属性组选项
      groupOptions: [],
      //当前对象，设置属性值使用
      current: null,
    };
  },
  props: ["category", "cancel"],
  watch: {
    category: {
      handler() {
        if (this.category) {
          this.drawer = true;
          this.title = `[${this.category.name}] 属性管理`;
          this.form.category_id = this.category.id;
          this.category_id = this.category.id;
          this.loadData();
        }
      },
      immediate: true,
    },
  },
  created() {
    this.loadEnums();
  },
  methods: {
    /**
     * 加载enum选项
     */
    loadEnums() {
      this.$http.get("common/enums").then((res) => {
        if (res.code == 0) {
          this.enums = res.data;
          this.types = res.data.property_types;
          if (this.types.length > 0) {
            this.activeName = this.types[0].value;
          }
        }
      });
    },
    /**
     * 加载属性
     */
    loadData() {
      this.loading = true;
      this.$http
        .get("admin/v1/property?categoryId=" + this.category_id)
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.data;
          }
        });
    },
    /**
     * 新增
     */
    onAdd() {
      this.dialogVisible = true;
      this.form = {
        category_id: this.category_id,
      };
      console.log(this.form);
    },
    /**
     * 修改
     */
    onEdit(item) {
      this.form = Object.assign({}, item);
      this.dialogVisible = true;
    },
    /**
     * 处理关闭事件
     */
    handleClose() {
      this.dialogVisible = false;
    },

    /**
     * 确认提交
     */
    confirmClick() {
      this.$refs.formProperty.validate((valid) => {
        if (valid) {
          if (
            this.form.input_type == 0 ||
            this.form.input_type == 1 ||
            this.form.input_type == 3
          ) {
            if (!this.form.id && this.valueTables.length == 0) {
              this.$message({
                type: "warning",
                message: "请添加属性值",
              });
              return;
            }
            this.form.values = this.valueTables;
          } else {
            this.form.values = [];
          }
          this.saving = true;
          this.$http
            .post("/admin/v1/property/edit", this.form)
            .then((res) => {
              this.loading = false;
              if (res.code == 0) {
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.loadData();
                this.dialogVisible = false;
              }
            })
            .finally(() => {
              this.saving = false;
            });
        }
      });
    },
    /**
     * 添加属性值
     */
    addValue() {
      if (this.valueTables.findIndex((x) => x.value == this.value) == -1) {
        this.valueTables.push({
          value: this.value,
        });
      }
      this.value = "";
    },
    /**
     * 删除属性值行
     */
    removeValue(item) {
      this.valueTables = this.valueTables.filter((x) => x.value != item.value);
    },
    /**
     * 远程加载属性组
     */
    remoteGroup(q) {
      console.log(q);
      this.remoteLoading = true;
      this.$http.get("admin/v1/common/select/group?q=" + q).then((res) => {
        if (res.code == 0) {
          this.groupOptions = res.data;
        }
        this.remoteLoading = false;
      });
    },
    /**
     * 删除属性
     */
    onDelProperty(item) {
      this.$http.get("admin/v1/property/del?id=" + item.id).then((res) => {
        if (res.code == 0) {
          this.tableData = this.tableData.filter((x) => x.id != item.id);
        }
      });
    },

    /**
     * 停用属性
     */
    stopSwitch(row) {
      console.log(row);
      this.$http
        .get(`admin/v1/property/toggle?id=${row.id}&isStop=${row.is_stop}`)
        .then((res) => {
          if (res.code != 0) {
            row.is_stop = !row.is_stop;
          }
        });
    },
    /**
     * 设置属性值
     */
    onSetValue(item) {
      this.current = item;
    },
  },
};
</script>

<style >
.drawer .el-drawer__body {
  padding-top: 0 !important;
}
.el-dropdown-link {
  cursor: pointer;
  /* color: var(--el-color-primary); */
  display: flex;
  align-items: center;
}

.el-dropdown .el-tooltip__trigger {
  display: flex;
  align-items: center;
}
</style>